.checkbox {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 0;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 2;
        opacity: 0;
        transition: all 0.3s;
    }

    > input {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        display: block;
        opacity: 0;
        z-index: 2;

        background: transparent;
        border: none;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        box-shadow: none;
    }

    &.disabled {
        opacity: 0.5;
    }

    &.form {
        min-height: 32px;
        min-width: 32px;
        color: white;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;

        &::before {
            width: 32px;
            height: 32px;
            background-color: white;
            border-radius: 2px;
        }

        &::after {
            margin-left: 8px;
            width: 16px;
            height: 16px;
            background-color: black;
            border-radius: 2px;
        }

        &.checked:after {
            opacity: 1;
        }

        > input {
            width: 32px;
            height: 32px;
        }

        > span {
            padding-left: 40px;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover:not(.checked):not(.disabled)::after {
                opacity: 0.5;
            }
        }
    }

    &.game {
        min-width: 56px;
        min-height: 56px;
        color: #cccccc;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;

        > span {
            padding-left: 64px;
        }

        &::before {
            content: "";

            width: 56px;
            height: 56px;

            background: linear-gradient(180deg, #cccccc 0%, #818181 100%);
            border-radius: 50%;
        }

        & > input {
            opacity: 1;
            top: 0;
            width: 0;
            height: 0;
            transform: none;
            margin-left: -99999px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
                width: 52px;
                height: 52px;
                margin-left: 99999px;

                transition: all 0.3s;

                background: #9d5e00;
                box-shadow: inset 0px 0px 16px #000000;
                border-radius: 50%;
                z-index: 0;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 6px;
                left: 6px;
                bottom: 6px;
                right: 6px;
                width: 44px;
                height: 44px;
                margin-left: 99999px;

                transition: all 0.3s;

                background: linear-gradient(180deg, rgba(238, 142, 0, 0.5) 0%, rgba(100, 60, 0, 0) 100%);
                box-shadow: inset 0px 0px 12px #670302;
                opacity: 0.5;
                border-radius: 50%;
                z-index: 0;
            }
        }

        &::after {
            width: 24px;
            height: 24px;
            background-color: white;
            border-radius: 50%;
            margin-left: 16px;
        }

        &.checked:after {
            opacity: 1;
        }

        > span {
            padding-left: 40px;
        }

        @media (hover: hover) and (pointer: fine) {
            &:not(.checked):hover:not(.disabled)::after {
                opacity: 0.5;
            }
        }
    }
}
