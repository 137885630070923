.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.95);
    opacity: 0;

    > header {
        > button {
            position: absolute;
            right: 104px;
            top: 104px;
            z-index: 1;

            transform: scale(var(--scale, 1));
            transform-origin: top right;

            > span {
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        > h1 {
            position: absolute;
            left: 0;
            right: 0;
            top: 104px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            transform: scale(var(--scale, 1));
            transform-origin: top center;

            color: white;
            font-size: 40px;
            line-height: 56px;
            height: 56px;
            font-weight: bold;
            text-align: center;
        }
    }

    > main {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%) scale(var(--scale, 1));
        transform-origin: center center;

        display: flex;
        flex-direction: column;
        justify-content: center;

        > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    > footer {
        > button:first-child {
            position: absolute;
            left: 104px;
            top: 50%;

            transform: translate(0, -50%) scale(var(--scale, 1));
            transform-origin: left center;
        }

        > button:last-child {
            position: absolute;
            right: 104px;
            top: 50%;

            transform: translate(0, -50%) scale(var(--scale, 1));
            transform-origin: right center;
        }

        > div {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 104px;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            transform: scale(var(--scale, 1));
            transform-origin: bottom center;

            & > *:not(:first-child) {
                margin-left: 24px;
            }
        }
    }

    &.mobile {
        > header {
            > button {
                right: 40px;
                top: 40px;
                z-index: 1;

                > span {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        > main {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 40px;
            left: 40px;
            width: calc(100% - 80px);
            transform: none !important;

            overflow: auto;
            overflow-x: hidden;
            overflow-y: overlay;

            align-items: center;
            justify-content: flex-start;

            > div {
                width: 100%;
                max-width: 480px;
                padding-bottom: 40px;

                > section {
                    &:not(:last-child) {
                        padding-bottom: 40px;
                    }

                    > img {
                        width: 100%;
                    }

                    > h1 {
                        width: 100%;
                        margin-top: 50px;
                        margin-bottom: 40px;

                        font-size: 24px;
                        line-height: 30px;
                        font-weight: 700;
                        color: white;
                        text-align: center;

                        transform: scale(var(--scale, 1));
                        transform-origin: center top;
                    }
                }
            }
        }
    }
}
