.inputbox {
    width: 100%;
    min-width: 28px;
    min-height: 28px;

    &:disabled {
        opacity: 0.5;
    }

    &.form {
        background: transparent;
        border: 2px solid white;
        border-radius: 4px;
        padding: 4px 8px;

        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: white;
    }
}
