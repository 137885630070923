.rattle-snake {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    > .renderer,
    > .splash,
    > .overlays {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    > .overlays {
        z-index: 10000;
        pointer-events: none;
        & > * {
            pointer-events: all;
        }
    }

    > .splash {
        z-index: 10001;
    }

    > *:not(.renderer):not(.splash) {
        opacity: 0;
        transition: opacity 0.3s;
    }

    &.game.entered > *:not(.renderer):not(.splash) {
        opacity: 1;
    }

    > footer {
        bottom: -100px;
        transition:
            opacity 0.3s,
            bottom 1s !important;
    }

    &.entered > footer {
        bottom: 0;
    }

    > .size,
    > .debug {
        position: absolute;
        left: 16px;
        top: 16px;
        padding: 16px;
        border-radius: 4px;
        background: rgba($color: #000, $alpha: 0.6);
        transform-origin: top left;
        transform: scale(var(--scale, 1));

        > span {
            font-size: 16px;
            color: #fff;
        }
    }

    > .presets {
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 16px;
        border-radius: 4px;
        background: rgba($color: #000, $alpha: 0.6);
        transform: scale(var(--scale));
        transform-origin: top right;

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;

            > li:not(:first-child) {
                margin-top: 8px;
            }

            button {
                width: 100%;
                text-align: center;
                text-transform: capitalize;

                height: 40px;
                min-height: 40px;
                > span {
                    font-size: 16px;
                }
            }
        }
    }

    &.mobile > .presets {
        top: 24px;
        right: calc(24px + 64px * var(--scale));

        gap: 8px;
        padding: 8px;

        > ul {
            display: flex;
            flex-direction: row;

            > li:not(:first-child) {
                margin-top: 0;
                margin-left: 4px;
            }

            > li {
                max-width: 40px;
                overflow: hidden;
                transition: all 0.3s;

                * {
                    font-size: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                }

                &:hover {
                    max-width: 100px;
                }
            }
        }

        @media (orientation: portrait) {
            bottom: 0;
            left: 50%;
            top: auto;
            right: auto;

            transform: translate(-50%, -110%) scale(var(--scale, 1));
            transform-origin: center bottom;
        }
    }

    &.mobile > .size {
        top: 24px;
        left: calc(24px + 64px * var(--scale));
        height: 56px;
        font-size: 20px;
    }

    &.mobile > .debug {
        top: 24px;
        left: 80px;
        padding: 16px;
        font-size: 24px;
    }
}
