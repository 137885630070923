.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba($color: #000, $alpha: 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #cccccc;

    padding: 16px 40px;
    padding-left: calc(var(--scale, 1) * 40px);
    padding-right: calc(var(--scale, 1) * 40px);
    padding-top: calc(var(--scale, 1) * 16px);
    padding-bottom: calc(var(--scale, 1) * 16px);

    > section {
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:not(:first-child) {
                margin-left: 32px;
            }

            > span {
                font-size: 20px;
                line-height: 20px;
                color: white;
                margin-left: 8px;
            }
        }

        > button:not(:first-child) {
            margin-left: 32px;
        }

        &:first-child {
            transform: scale(var(--scale, 1));
            transform-origin: left center;
        }

        &:last-child {
            transform: scale(var(--scale, 1));
            transform-origin: right center;
        }

        &:not(:first-child):not(:last-child) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(var(--scale, 1));
            transform-origin: center center;
        }
    }

    &.mobile {
        font-size: 12px;
        line-height: 16px;

        padding: 16px 24px;
        padding-left: calc(var(--scale, 1) * 24px);
        padding-right: calc(var(--scale, 1) * 24px);
        padding-top: calc(var(--scale, 1) * 16px);
        padding-bottom: calc(var(--scale, 1) * 16px);

        > section {
            // &:first-child {
            //     display: none;
            // }

            // &:not(:first-child):not(:last-child) {
            //     position: relative;
            //     left: 0;
            //     top: 0;
            //     transform: none;
            // }

            > div {
                flex-direction: column;
                align-items: flex-start;

                > span {
                    font-size: 16px;
                    line-height: 20px;
                    margin-left: 0;
                }
            }
        }

        > button {
            position: fixed;
            top: 24px;
            font-size: 24px;
            padding: 16px;
            transform: scale(var(--scale, 1));

            &.home {
                left: 24px;
                transform-origin: top left;
            }

            &.fullscreen {
                right: 24px;
                transform-origin: top right;
            }
        }
    }
}
