@mixin landscape {
    .controls {
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: none;
        pointer-events: none;
        & > * {
            pointer-events: all;
        }

        > div {
            position: absolute;
            top: 50%;

            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 104px;
            grid-template-rows: 70px 104px 104px;
            transform: translate(0, -50%) scale(var(--scale, 1));
            gap: 8px;

            &:first-child {
                left: 24px;
                transform-origin: center left;
            }

            &:last-child {
                right: 24px;
                transform-origin: center right;
            }

            > section {
                grid-column: 1 !important;

                &.amount {
                    grid-row: 1;
                }

                &.bet {
                    grid-row: 2;
                }

                &.spin {
                    grid-row: 3;
                }

                &.options {
                    grid-row: 3;
                }
            }
        }
    }
}

.controls {
    display: grid;
    grid-template-columns: 104px 104px 104px;
    grid-template-rows: auto 104px;
    gap: 16px;

    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%, 0) scale(var(--scale, 1));
    transform-origin: center bottom;

    > div {
        display: contents;
    }

    > section,
    > div > section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        & > * {
            width: 100%;
            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        > section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;

            & > * {
                height: 100%;
                &:not(:first-child) {
                    margin-left: 8px;
                }
            }
        }

        &.amount {
            grid-row: 1;
            grid-column: 2;

            > span {
                background-color: rgba($color: #fff, $alpha: 0.7);
                border: 3px solid #000;
                border-radius: 4px;
                padding: 10px 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-weight: 700;

                > h5 {
                    font-size: 12px;
                    line-height: 14px;
                    color: #686868;
                }

                > span {
                    font-size: 20px;
                    line-height: 30px;
                    color: #000;
                }
            }
        }

        &.options {
            grid-row: 2;
            grid-column: 1;
        }

        &.spin {
            grid-row: 2;
            grid-column: 2;

            > button {
                height: 100%;
                width: 100%;

                > span {
                    display: flex;
                    flex-direction: column;

                    > svg {
                        font-size: 40px;

                        &:only-child {
                            font-size: 72px;
                        }
                    }

                    > span {
                        font-size: 24px;
                        line-height: 30px;
                        font-weight: 700;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        &.bet {
            grid-row: 2;
            grid-column: 3;

            button {
                width: 100%;
                > span {
                    > svg {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

@media (orientation: landscape) {
    @include landscape;
}

:global(.forced-landscape) {
    @include landscape;
}
