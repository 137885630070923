@font-face {
    font-family: "MontrenaSlab";
    src: url("./fonts/montrena_slab-webfont.woff2") format("woff2"),
        url("./fonts/montrena_slab-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "MontrenaRough";
    src: url("./fonts/montrena_rough-webfont.woff2") format("woff2"),
        url("./fonts/montrena_rough-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
