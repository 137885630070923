.home {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    background-size: cover;
    background-position: center center;

    display: flex;
    flex-direction: column;
    align-content: center;

    overflow: hidden;

    // > header {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;

    //     & > img {
    //         max-width: 80vw;
    //         object-fit: contain;
    //     }

    //     @media screen and (max-width: 1199px) {
    //         & > img {
    //             max-width: 400px !important;
    //         }
    //     }

    //     @media screen and (max-width: 699px) {
    //         padding: 0 8px;

    //         & > img {
    //             max-width: 300px !important;
    //         }
    //     }
    // }

    > main {
        position: absolute;
        top: 45%;
        left: 0;
        right: 0;
        width: 100%;
        overflow: hidden;
        transform: translate(0, -50%);

        > div {
            display: flex;
            flex-direction: row;
            transform: translate(calc(var(--tab) * -56vw), 0);
            transition: transform 0.5s;

            > section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 56vw;
                min-width: 56vw;
                transform: translate(22vw, 0) scale(0.8);
                transition: transform 0.3s;
                transform-origin: center center;

                > img {
                    width: 100%;
                    max-height: calc(0.7 * var(--screen-height));
                    object-fit: contain;
                    opacity: 1;
                    transition: opacity 0.3s;
                }

                > div {
                    width: auto;
                    max-width: 960px;
                    margin-top: 16px;
                    padding: 24px 32px;

                    background: rgba($color: #000000, $alpha: 0.5);
                    border-radius: 8px;
                    opacity: 0;
                    transition: opacity 0.3s;

                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 700;
                    color: #ffffff;

                    transform: scale(var(--scale, 1));
                    transform-origin: top center;

                    > h2 {
                        font-size: 20px;
                        line-height: 24px;
                        color: #920302;

                        &:not(:only-child) {
                            margin-bottom: 14px;
                        }
                    }
                }

                &.active {
                    transform: translate(22vw, 0) scale(1);

                    > img {
                        opacity: 1;
                    }

                    > div {
                        opacity: 1;
                        transition: opacity 0.3s 0.3s;
                    }
                }
            }
        }
    }

    > footer {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 50%;
        transform: translate(-50%, -50%) scale(var(--scale, 1));
        transform-origin: center bottom;

        display: flex;
        flex-direction: row;
        justify-content: center;

        display: grid;
        grid-template-columns: 100px 230px 100px;
        grid-template-rows: auto;
        gap: 24px;

        > button {
            svg {
                font-size: 32px;
                font-weight: 700;
            }
        }
    }

    &.mobile {
        > main {
            > div {
                transform: translate(calc(var(--tab) * -70vw), 0);
                > section {
                    width: 70vw;
                    min-width: 70vw;
                    transform: translate(15vw, 0) scale(0.98);

                    > div {
                        width: 480px;
                        padding: 20px 20px;
                    }

                    &.active {
                        transform: translate(15vw, 0) scale(1);
                    }
                }
            }
        }
    }
}

@media (orientation: landscape) {
    .home {
        &.mobile {
            > main {
                > div {
                    transform: translate(calc(var(--tab) * -50vw), 0);
                    > section {
                        width: 50vw;
                        min-width: 50vw;
                        transform: translate(25vw, 0) scale(0.98);

                        > img {
                            max-height: calc(0.5 * var(--screen-height));
                        }

                        > div {
                            margin-top: 0;
                        }

                        &.active {
                            transform: translate(25vw, 0) scale(1);
                        }
                    }
                }
            }
        }
    }
}

:global(.forced-landscape) {
    .home {
        &.mobile {
            > main {
                > div {
                    transform: translate(calc(var(--tab) * -50vh), 0);
                    > section {
                        width: 50vh;
                        min-width: 50vh;
                        transform: translate(25vh, 0) scale(0.98);

                        > img {
                            max-height: 50vh;
                        }

                        > div {
                            margin-top: 0;
                        }

                        &.active {
                            transform: translate(25vh, 0) scale(1);
                        }
                    }
                }
            }
        }

        overflow: visible;
    }
}
