@mixin landscape {
    .modal {
        &.mobile {
            > header {
                > h1 {
                    right: auto;
                    left: 40px;
                    width: auto;
                }
            }
        }
    }
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.95);
    opacity: 0;

    > header {
        > button {
            position: absolute;
            right: 104px;
            top: 104px;

            transform: scale(var(--scale, 1));
            transform-origin: top right;

            > span {
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        > h1 {
            position: absolute;
            left: 0;
            right: 0;
            top: 104px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            transform: scale(var(--scale, 1));
            transform-origin: top center;

            color: white;
            font-size: 40px;
            line-height: 56px;
            height: 56px;
            font-weight: bold;
            text-align: center;
        }
    }

    > main {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%) scale(var(--scale, 1));
        transform-origin: center center;

        width: 424px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        > section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &:not(:first-child) {
                margin-top: 8px;
            }

            > input {
                width: 80px;
            }

            > label {
                flex: 1;
            }

            &:last-of-type {
                margin-top: 40px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 16px;
            }
        }

        > footer {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }
    }

    &.mobile {
        > header {
            > button {
                right: 40px;
                top: 40px;

                > span {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            > h1 {
                top: 40px;

                font-size: 24px;
                line-height: 56px;
                height: 56px;
            }
        }
    }
}

@media (orientation: landscape) {
    @include landscape;
}

:global(.forced-landscape) {
    @include landscape;
}
